import * as React from "react"

const SageInfo = () => (
  <>
    <section className="relative mb-7 w-full py-3 md:py-10">
      <div className="mt-3 md:mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div className="text-center">
          <h3 className="text-3xl font-bold sm:text-4xl" data-aos="fade-up">
            <span className="block text-emerald-800">SEIN Terminal</span>
          </h3>
          <p
            className="mt-3 max-w-md mx-auto md:mt-5 md:max-w-3xl text-base md:text-xl"
            data-aos="fade-up"
          >
            <span className="text-emerald-800">SEIN Terminal</span> is the
            gateway to our ecosystem. It provides access to our proprietary
            applications, tools and resources.
          </p>
        </div>
      </div>
    </section>
  </>
)

export default SageInfo
