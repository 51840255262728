import * as React from "react"
import Layout from "../components/layout"
import { navigate } from "gatsby"
import Seo from "../components/seo"
import Banner from "../components/Banner"
// import Stats from "../components/Stats"
import SolutionHighlights from "../components/SolutionHighlights"
import Partners from "../components/partners"
import AboutSection from "../components/AboutSection"
import Button from "../components/UI/Button"
import SageInfo from "../components/SageInfo"
// import Navbar from "../components/Navbar"
import { PopupModal } from "react-calendly"

const IndexPage = () => {
  const [calendlyIsOpen, setCalendlyIsOpen] = React.useState(false)

  const calendlyPageSettings = {
    primaryColor: "0FCA98",
    textColor: "4d5055",
  }

  return (
    <Layout>
      <Seo title="Home" />
      <PopupModal
        url="https://calendly.com/aah-1/sein-terminal-informational-session"
        onModalClose={() => setCalendlyIsOpen(false)}
        open={calendlyIsOpen}
        pageSettings={calendlyPageSettings}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={
          typeof document !== "undefined"
            ? document.getElementById("___gatsby")!
            : null!
        }
      />
      <Banner
        btn={
          <Button
            addClass="xl:w-2/5 md:mt-15 mt-5"
            clicked={() => setCalendlyIsOpen(true)}
          >
            {"Schedule a Demo"}
          </Button>
        }
      />
      {/* <Navbar /> */}
      <SageInfo />
      {/* <Stats /> */}
      <AboutSection />
      <SolutionHighlights />
      <Partners />
    </Layout>
  )
}

export default IndexPage
