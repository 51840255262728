import React from "react"
import bannerImg from "../../images/hero-banner.png"
import HeroImg from "../../images/banner.png"

import BannerMain from "./BannerMain"


const Banner = ({ btn}: { btn?: JSX.Element}) => {
  return (
    <>
      <section
        id="home"
        className="header relative overflow-hidden pt-2 md:pt-8 pb-8 items-center flex w-full bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url(${bannerImg})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap" >
          <BannerMain
            title="Bring your real estate"
            titleTag="into the future"
            subTitle="Unleash the connecting power of the"
            subTag="Sein Terminal"
            btn={btn}
            img={HeroImg}
          />
        </div>
      </section>
    </>
  )
}

export default Banner
