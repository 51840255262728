import React from "react"

const FeatureItem = (
    {img,title,paragraph}:{img:string, title:string, paragraph:string}
    ) => {
  return (
    <>
        <div className="p-4 md:w-1/3" data-aos="fade-up">
            <div className="flex flex-col h-full  border border-themeBorder overflow-hidden p-6  rounded-lg  ease-linear transition-all duration-200   hover:shadow-lg cursor-pointer">
                <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img className="" src={img} />
                    </div>
                    <h2 className="text-textColor text-lg title-font font-medium">
                        {title}
                    </h2>
                </div>
                <div className="flex-grow">
                    <p className="leading-relaxed text-sm">
                        {paragraph}
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default FeatureItem
