import React from "react"
import CurveImg from "../../images/vector.png"
import DotCircleImg from "../../images/dot-circle.png"
import FrameImg from "../../images/Frame.png"
import ProductFeatures from "../ProductFeatures"
import SectionTitle from "../SectionTitle"

const AboutSection = () => {
  return (
    <>
      <section
        className="relative 
                bg-themeGray 
                body-font 
                bg-themeGray 
                w-full pt-10 pb-20
                md:mt-[180px]
                mt-0 
                "
      >
        <div
          className="wave hidden lg:block 
                        absolute
                        top-[70px]
                        left-[45%]
                        right-0
                        z-[2]
                    "
        >
          <img className="w-20" src={DotCircleImg} />
        </div>
        <div
          className="wave hidden lg:block 
                        absolute
                        -top-[185px]
                        left-0
                        right-0
                        -z-[3]
                    "
        >
          <img className="w-full" src={CurveImg} />
        </div>
        <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
          <SectionTitle
            titleHead="About Us"
            title="Our mission is to redefine"
            spanTag="how real estate is done"
            subTitle="STP has created the operating system for the real-estate industry. The operating
            system provides the foundation for standardization, reliance on data to make
            decisions and greater accountability. This is accomplished by digitizing industry
            standard practices and leveraging different forms of artificial intelligence: machine
            learning, natural language processing and the blockchain. "
          />
          <ProductFeatures />
        </div>
        <div
          className="wave hidden lg:block 
                        absolute
                        bottom-[50px]
                        right-[40px]
                        left-auto
                        z-[2]
                    "
        >
          <img className="w-32" src={FrameImg} />
        </div>
      </section>
    </>
  )
}

export default AboutSection
