import React from "react"
import ModularImg from "../../images/modular.png"
import NotificationImg from "../../images/notification.png"
import ResourceImg from "../../images/resource.png"
import AIImg from "../../images/ai.png"
import CloudBasedImg from "../../images/cloud.png"
import LessonImg from "../../images/lesson.png"

import FeatureItem from "./FeatureItem"

const ProductFeatures = () => {
  return (
    <>
      <div className="flex flex-wrap -mx-4">
        <FeatureItem
          img={`${AIImg}`}
          title="Artificial Intelligence"
          paragraph="Utilize the data generated by your team to receive A.I based
          recommendations custom to your organization and projects."
        />
        <FeatureItem
          img={`${NotificationImg}`}
          title="Real Time Notifications"
          paragraph="Never miss an important event again with customizable notifications."
        />
        <FeatureItem
          img={`${ModularImg}`}
          title="Modular Structure"
          paragraph="Build out the execution strategy based on how your business
          operates and what the specific project needs."
        />
        <FeatureItem
          img={`${CloudBasedImg}`}
          title="Cloud Based"
          paragraph="Easy accessible from anywhere, anytime providing 24/7 project insight."
        />
        <FeatureItem
          img={`${ResourceImg}`}
          title="Data Repository"
          paragraph="Have all of your relevant and necessary information in one
          centralized place."
        />
        <FeatureItem
          img={`${LessonImg}`}
          title="Standardization"
          paragraph="Create company standards that are quickly deployed for
          each new project and/or opportunity."
        />
      </div>
    </>
  )
}

export default ProductFeatures
